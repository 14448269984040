<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>产品管理</el-breadcrumb-item>
      <el-breadcrumb-item>开通订单</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <!-- 筛选 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col>
          <span>订单筛选</span>
        </el-col>
      </el-row>
      <el-form
        :model="queryForm"
        class="filter_Form"
        label-width="80px"
        ref="queryFormRef"
      >
        <el-row :gutter="50">
          <el-col :span="6">
            <el-form-item label="姓名" prop="userName">
              <el-input
                v-model="queryForm.userName"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="6">
            <el-form-item label="所属赛区" prop="districtId">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                v-model="queryForm.districtId"
                clearable
              >
                <el-option
                  v-for="item in dict_district"
                   :key="item.id"
                  :label="item.districtName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :span="6">
            <el-form-item label="赛季" prop="season">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                v-model="queryForm.season"
                clearable
              >
                <el-option
                  v-for="item in dict_season"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="手机号" prop="phone">
              <el-input
                v-model="queryForm.phone"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="产品名称" prop="productName">
              <el-input
                v-model="queryForm.productName"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row :gutter="50">
          <el-col :span="6">
            <el-form-item label="订单名称" prop="productIds">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                v-model="queryForm.productIds"
                multiple
                clearable
              >
                <el-option
                  v-for="item in dict_productIds"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="赛季" prop="season">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                v-model="queryForm.season"
                clearable
              >
                <el-option
                  v-for="item in dict_season"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="起止时间" prop="dateTimeRange">
              <el-date-picker
                v-model="queryForm.dateTimeRange"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="timestamp"
                clearable
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row> -->
        <el-row :gutter="20" type="flex" justify="end">
          <el-col :span="2">
            <el-button type="primary" style="width: 100%" @click="getDataList"
              >查询</el-button
            >
          </el-col>
          <el-col :span="2">
            <el-button type="primary" style="width: 100%" @click="reset"
              >重置</el-button
            >
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <!-- 订单列表 -->
    <el-card class="list_card" style="margin-top: 20px">
      <el-row class="title_row">
        <el-col :span="12">
          <div class="list_title">订单列表</div>
        </el-col>
        <el-col :span="12">
          <div style="text-align: right">
            <el-button type="primary" @click="openOrderListClicked"
              >开通产品</el-button
            >
          </div>
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="dataList"
        border
        stripe
      >
        <el-table-column label="序号" type="index"></el-table-column>
        <el-table-column
          label="赛季"
          prop="season"
          width="100px"
        ></el-table-column>
                <el-table-column
          label="产品名称"
          prop="productName"
        ></el-table-column>
        <el-table-column
          label="开始时间"
          prop="beginTime"
        ></el-table-column>
        <el-table-column
          label="截止时间"
          prop="endTime"
        ></el-table-column>
        <el-table-column label="购买用户" prop="userName"></el-table-column>
        <el-table-column
          label="手机号"
          prop="phone"
        ></el-table-column>
        <!-- <el-table-column label="赛区名称" prop="districtName"></el-table-column> -->
        <el-table-column
          label="学校名称"
          prop="schoolName"
        ></el-table-column>
        <!-- <el-table-column
          label="手填学校名称"
          prop="fillSchoolName"
          width="100px"
        ></el-table-column> -->
        <!-- <el-table-column label="年级" prop="grade"></el-table-column>
        <el-table-column label="班级" prop="classes"></el-table-column>
        <el-table-column label="收件人" prop="receiptName"></el-table-column>
        <el-table-column
          label="收件人电话"
          prop="receiptPhone"
          width="80px"
        ></el-table-column>
        <el-table-column
          label="收件地址"
          prop="receiptAddress"
          width="150px"
        ></el-table-column> -->
        <!-- <el-table-column label="订单编号" prop="orderNum"></el-table-column>
        <el-table-column label="订单价格" prop="orderPrice"></el-table-column>
        <el-table-column label="支付类型" prop="paidType"></el-table-column> -->
        <!-- <el-table-column
          label="备注"
          prop="remark"
          width="150px"
        ></el-table-column> -->
        <!-- <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              icon="el-icon-delete"
              size="small"
              @click="showCloseProductDialog(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column> -->
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.pageNum"
        :page-sizes="[10, 20, 40, 80]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 开通小程序产品的Dialog -->
    <el-dialog
      title="开通小程序产品"
      :visible.sync="openProductDialogVisible"
      width="50%"
      @close="openProductDialogVisibleClose"
    >
      <el-form
        :model="openProductForm"
        ref="openProductFormRef"
        :rules="openProductFormRules"
        label-width="140px"
      >
        <el-form-item label="选择产品" prop="productDetailIdList">
          <el-select
            placeholder="请选择"
            style="width: 100%"
            v-model="openProductForm.productDetailIdList"
            ref="openProductRef"
            multiple
          >
            <el-option
              v-for="item in productList"
              :key="item.id"
              :label="item.openName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="校验个人信息" prop="checkUserInfo">
          <el-switch v-model="openProductForm.checkUserInfo"></el-switch>
        </el-form-item> -->
        <el-form-item label="开通时长（天）" prop="days">
          <el-input
            type="number"
            placeholder="不填，则默认当前赛季有效"
            v-model="openProductForm.days"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="小程序账号" prop="phones">
          <el-input
            type="textarea"
            placeholder="请输入,批量开通账号请以“英文逗号”分隔"
            v-model="openProductForm.phones"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="开通原因" prop="remark">
          <el-input
            type="textarea"
            placeholder="请输入"
            v-model="openProductForm.remark"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="注意">
          <div style="color: red">
            1.开通时长，不填则默认当前赛季有效，新赛季时失效
          </div>
          <div style="color: red">2.批量开通账号请以“英文逗号”分隔</div>
          <div style="color: red">
            3.开通原因，请稍加详细说明，避免核查时产生问题
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="openProduct">开 通</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import $common from '../../../methods/common'
import { queryDistrictPage, getProductListData, queryOpenProductList, openProducts } from '@/http/api'

export default {
  data () {
    return {
      productList: [],
      queryForm: {
        userName: '',
        phone: '',
        pageNum: 1,
        pageSize: 10
      },
      // 退费消息数
      refundBadge: '',
      total: 0,
      dict_season: this.$userInfo.getDataList(this, 't_season').then(value => { this.dict_season = value }),
      dict_district: [],
      dataList: [],
      dict_productIds: [],
      openProductDialogVisible: false,
      openProductForm: {
        productDetailIdList: [],
        days: '',
        phones: '',
        remark: ''
      },
      openProductFormRules: {
        productDetailIdList: [{ required: true, message: '请选择', trigger: 'change' }],
        phones: [{ required: true, message: '请输入', trigger: 'blur' }],
        remark: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      closeProductDialogVisible: false,
      closeProductForm: {},
      closeProductFormRules: {
        closeCause: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      roleWildcard: window.sessionStorage.getItem('roleWildcard')
    }
  },
  computed: {
    $common () {
      return $common
    }
  },
  created () {
    // this.initTime()
    // this.getrefundBadge()
    this.getDistrictList()
    this.getProductListData()
    this.getDataList()
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    // 获取可开通产品列表
    getProductListData () {
      getProductListData().then((res) => {
        this.productList = res.data
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 获取赛区筛选数据
    getDistrictList () {
      queryDistrictPage({ pageNum: 1, pageSize: 10000 }).then((res) => {
        this.dict_district = res.data.list
        console.log('this.editInfoForm', this.editInfoForm)
        if (this.editInfoForm.id) {
          this.getSchoolContactsData()
        }
      }).catch((err) => {
        console.log('err', err)
      })
    },
    getDataList () {
      queryOpenProductList(this.queryForm).then((res) => {
        res.data.list.forEach(item => {
          if (!item.expireAt) {
            item.expireAt = '本赛季有效'
          }
        })
        this.dataList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('err', err)
      })
    },
    reset () {
      this.$refs.queryFormRef.resetFields()
      this.getDataList()
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getDataList()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getDataList()
    },
    openOrderListClicked () {
      this.openProductDialogVisible = true
    },
    async exportOrderList () {
      if (!this.searchRole(0)) return this.$message.warning('如需导出超出当前时间3天以上的订单，请联系科技或超级管理员')
      const { data: res } = await this.$http.get('/product/erp/exportOrderList', {
        params: this.queryForm
      })
      this.$common.closeLoading()
      if (res.code !== 0) {
        return this.$message.error(res.message)
      }
      var url = res.data.fileUrl
      var link = document.createElement('a')
      try {
        link.href = url
      } catch (error) {
        link.href = window.URL.createObjectURL(url)
      }
      link.click()
    },

    searchRole (flag) {
      switch (flag) {
        case 0: // 订单列表导出
          // 非管理员只能导出5天内的订单
          if (window.sessionStorage.getItem('roleWildcard') !== 'L') {
            if (new Date().getTime() - this.queryForm.dateTimeRange[0] >= 5 * 24 * 60 * 60 * 1000) {
              return false
            }
            return true
          }
          return true
        default:
          console.log('未知的flag')
          break
      }
    },
    openProductDialogVisibleClose () {
      this.$refs.openProductFormRef.resetFields()
    },
    openProduct () {
      this.$refs.openProductFormRef.validate(async valid => {
        if (!valid) return
        this.openProductRequest()
      })
    },
    openProductRequest () {
      openProducts(this.openProductForm).then((res) => {
        if (res.code === 200) {
          this.$message.success('开通成功')
          this.openProductDialogVisible = false
          this.getDataList()
          if (res.data.length !== 0) {
            return this.$alert(res.data, '以下用户未注册', {
              confirmButtonText: '确定',
              type: 'warning',
              dangerouslyUseHTMLString: true
            })
          }
        } else {
          return this.$alert(res.message, '开通有误', {
            confirmButtonText: '确定',
            type: 'warning',
            dangerouslyUseHTMLString: true
          })
        }
      }).catch((err) => {
        console.log('err', err)
      })
    },
    showCloseProductDialog (row) {
      this.closeProductForm = {
        name: row.userName,
        productName: row.orderName,
        pruchaseId: row.orderId
      }
      this.closeProductDialogVisible = true
    }
  }
}
</script>

<style>
</style>
